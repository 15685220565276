import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const FirstTime = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">Is this your client's first home?</div>
            <p class="ae-form-paragraph">Select from the drop down below to let us know.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">First time homebuyer?</div>
                    <select
                        class="ae-form-field w-input"
                        id="firstTime"
                        name="firstTime"
                        value={data.firstTime}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
            </div>
        </SlideFade>
        </div>
        </>
    )

    return content
}
export default FirstTime