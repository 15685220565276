import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css'
import './webflow.css'
import './index.css';
import App from './App';
import { AuthContextProvider } from './Context/AuthContext';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

//extend the theme
const fonts = {
  body: 'Rubik'
}

const lineHeight = {
  normal: '1.5em'
}

const theme = extendTheme({ fonts, lineHeight })

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ChakraProvider theme={theme} resetCSS='true'>
        <App />
      </ChakraProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
