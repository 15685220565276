import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Credit = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What is your client's credit like?</div>
            <p class="ae-form-paragraph">Enter the credit score you will be using for your client.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Enter credit score</div>
                <input
                    type="number" 
                    class="ae-form-field w-input" 
                    id="creditScore"
                    name="creditScore"
                    value={data.creditScore}
                    onChange={handleChange} 
                />
            </div>
            </SlideFade>
        </div>
        </>        
    )

    return content
}
export default Credit