import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../Firebase/config"

//firebase imports
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore"

export const useCollection = (c, _q, _order) => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    //setup query & order
    const q = useRef(_q).current
    const order = useRef(_order).current

    useEffect(() => {
        let ref = collection(projectFirestore, c)

        if (q) {
            ref = query(ref, where(...q))
        }

        if (order) {
            ref = query(ref, orderBy(...order))
        }

        const unsub = onSnapshot(ref, (snapshot) => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id})
            })
            setDocuments(results)
        }, (error) => {
            console.log(error)
            setError('Could not fetch the data.')
        })

        return () => unsub()
    }, [c, q, order])

    return {documents, error}
}