import DashNav from '../../Components/DashNav'

import './Help.css'

export default function Help() {
  return (
    <DashNav>
        <h2>Help</h2>
    </DashNav>
  )
}
