import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDi0_qpMRcXqX7__6OPOu23sY8Ic51X15k",
  authDomain: "ae-app-df8cc.firebaseapp.com",
  projectId: "ae-app-df8cc",
  storageBucket: "ae-app-df8cc.appspot.com",
  messagingSenderId: "494866674966",
  appId: "1:494866674966:web:5fff9bf6ede03bc4b04c37",
  measurementId: "G-X0S1J1T8SE"
};

//init firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

//init service
const projectFirestore = getFirestore()
const projectAuth = getAuth()

// timestamp
const timestamp = Timestamp

export { projectFirestore, projectAuth, timestamp, analytics }