import './NavBar.css'
import '../Styles/AEWebflow.css'
import { Link } from 'react-router-dom'

export default function NavBar(props) {
    
    return (
        <div className="nav-div">
            <div className="nav-container">
                <div className='mobile-logo-div'>
                    <img src="/img/ae-logo.svg" loading="lazy" width="60" alt="AE Logo" />
                </div>
                <a href="#" className="logo-div w-inline-block"><img src="/img/ae-text-logo.svg" loading="lazy" width="244" alt="" /></a>
                <div className="links-div">
                    <ul role="list" className="links-list w-list-unstyled">
                        <li className="nav-item">
                            <Link to={props.link} className="button w-button">{props.title}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
