import useFormContext from "../../Hooks/useFormContext"
import { useFindLender } from "../../Hooks/useFindLender"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'
import './FindLender.css'
import Results from "../Results"

const FindLender = () => {

    const { stackResponse, gotStackResponse, gotResponse, jsonError, data } = useFormContext()
    const { error, isPending } = useFindLender()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">{!gotResponse ? "It's time to find a lender" : "Potential Lenders"}</div>
            {gotResponse && <p className="ae-form-paragraph">Keep in mind you will need to verify with the lender to make sure they will fund the loan. Based on our database and AI the probability is high.</p>}
            {!gotResponse && <p class="ae-form-paragraph">Once you submit your criteria our AI will go through our entire database of lenders to match your client with the best possible match.<br/><br/></p>}
            {!gotResponse && <p class="ae-form-paragraph">Keep in mind this may take up to 30 seconds.</p>}
        </div>
        </SlideFade>

        {error && <div className="results-error-holder">{error}</div>}
        {jsonError && <div className="results-error-holder">{jsonError}</div>}

        {!gotStackResponse && <Results lenders={stackResponse} data={data} />}

        </>
    )

    return content
}
export default FindLender