import { FormProvider } from '../../Context/FormContext'
import { useCollection } from '../../Hooks/useCollection'
import { useAuthContext } from '../../Hooks/useAuthContext'
import { SlideFade } from '@chakra-ui/react'

//styles
import './Home.css'

//pages & components
import DashNav from '../../Components/DashNav'
import Form from '../../Components/Form'

export default function Home() {
    const { user } = useAuthContext()
    const { documents, error } = useCollection(
        'userInfo',
        ["user", "==", user.uid])

    return (
        <div>
            <DashNav>
                <div className='dash-main-header'>
                    {error && <h2 className='dash-main-heading'>Welcome back!</h2>}
                    {documents && documents.map(info => (
                        <h2 key={info.id}>Welcome, {info.firstName}</h2>
                    ))}
                    <p>Find a lender for your client in seconds, not hours.</p>
                </div>
                
                <FormProvider>
                    <Form />
                </FormProvider>
            </DashNav>
        </div>
    )
}