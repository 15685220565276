import { useEffect, useState } from "react"
import { projectAuth } from '../Firebase/config'
import { useAuthContext } from './useAuthContext'
import { signInWithEmailAndPassword } from "firebase/auth"

export const useLogin = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const login = async (email, password) => {
        setError(null)
        setIsPending(true)

        // sign user out
        try {
            const res = await signInWithEmailAndPassword(projectAuth, email, password)

            //dispatch logout action
            dispatch({type: 'LOGIN', payload: res.user})

            // update state
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        } catch(err) {
            if (!isCancelled) {
                console.log(err.code)
                console.log(err.message)
                if (err.code === 'auth/invalid-login-credentials') {
                    setError('Your email or password is incorrect, please try again.')
                    setIsPending(false)
                } else {
                    setError('This is embarassing, something went wrong.')
                    setIsPending(false)
                }
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return {login, error, isPending}
}