import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Property = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What type of property?</div>
            <p class="ae-form-paragraph">Tell us what type of property is your client looking to purchase.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Select property type:</div>
                    <select
                        class="ae-form-field w-input"
                        id="propertyType"
                        name="propertyType"
                        value={data.propertyType}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="Single Family">Single Family</option>
                        <option value="Single Family Rental (SFR)">Single Family Rental (SFR)</option>
                        <option value="PUD">PUD</option>
                        <option value="Townhome">Townhome</option>
                        <option value="2-Unit (Duplex)">2-Unit (Duplex)</option>
                        <option value="3-Unit (Triplex)">3-Unit (Triplex)</option>
                        <option value="4-Unit (Quadruplex)">4-Unit (Quadruplex)</option>
                        <option value="5-8 Unit Property">5-8 Unit Property</option>
                        <option value="9 Units or More">9 Units or More</option>
                        <option value="Warrantable Condo">Warrantable Condo</option>
                        <option value="Non-Warrantable Condo">Non-Warrantable Condo</option>
                        <option value="Condotel/PUDtel">Condotel/PUDtel</option>
                        <option value="Cooperative">Cooperative</option>
                        <option value="Manufactured Home">Manufactured Home</option>
                    </select>
            </div>
        </SlideFade>
        </div>
        </>
    )

    return content
}
export default Property