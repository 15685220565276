import './Login.css'
import NavBar from '../../Components/NavBar'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useLogin } from '../../Hooks/useLogin'
import { CircularProgress } from '@chakra-ui/react'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isPending } = useLogin()

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }

  return (
    <>

    <NavBar link="/signup" title="Sign Up" />

    <section className="container">
        <div className="content-holder">
            <div className="form-holder">
                <h2 className="heading-6">Welcome back!</h2>
                <div className="text-block-58">Are you a new user? <Link to="/signup">Create an account.</Link>
                </div>
                <div className="w-form">
                    <form onSubmit={handleSubmit} id="email-form" name="email-form" className="form">
                    
                        <label className="ae-form-label">Email Address</label>
                        <input 
                            type="email" 
                            className="ae-form-field w-input" 
                            onChange={(e) => setEmail(e.target.value)}
                            value={email} 
                            required />
                        
                        <label className="ae-form-label">Password</label>
                        <input 
                            type="password" 
                            className="ae-form-field w-input"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password} 
                            required />
                        
                        {!isPending && <button value="Login" className="submit-button w-button">Login</button>}
                        {isPending && <CircularProgress isIndeterminate color='#0084FF' size='44px'/>}
                        {/* {isPending && <button value="Logging in" className="submit-button w-button">Logging in</button>} */}

                        {error && <div className="form-fail">
                            <p className='error-text'>{error}</p>
                        </div>}

                    </form>
                </div>
                <div className="login-links">
                <a href="#" className="login-link">Forgot password?</a>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}
