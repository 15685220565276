import { useReducer, useEffect, useState } from "react"
import { projectFirestore, timestamp } from "../Firebase/config"
import { collection, addDoc, deleteDoc, updateDoc, doc } from "firebase/firestore"

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { ...state, isPending: true, document: null, success: false, error: null}
        case 'ADDED_DOCUMENT':
            return { ...state, isPending: false, document: action.payload, success: true, error: null}
        case 'UPDATED_DOCUMENT':
            return { ...state, isPending: false, document: action.payload, success: true, error: null}
        case 'DELETED_DOCUMENT':
            return { ...state, isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return {...state, isPending: false, document: null, success: false, error: action.payload}
        default:
            return state
    }
}

export const useFirestore = (col) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    // collection ref
    const ref = collection(projectFirestore, col)

    // only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)  
        }
    }

    // add document
    const addDocument = async (doc) => {
        dispatch({ type: 'IS_PENDING' })
        setError(null)
        setSuccess(null)

        try {
            const createdAt = timestamp.fromDate(new Date())
            const addedDocument = await addDoc(ref, { ...doc, createdAt: createdAt})
            
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument})

            setSuccess("Successfully added your search!")
        } catch (err) {
            dispatchIfNotCancelled({type: 'ERROR', payload: err.message })
            setError("Something went wrong trying to save this item. Please try again.")
        }
    }

    // delete document
    const deleteDocument = async (id) => {
        dispatch({ type: 'IS_PENDING' })
        setError(null)
        setSuccess(null)

        try {
            await ref.doc(id).delete()
            dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT'})

            setSuccess("Successfully deleted!")
        } catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: 'Could not delete item.'})
            setError("Something went wrong trying to delete this item. Please try again.")
        }
    }

    // update documents
    const updateDocument = async (id, updates) => {
        dispatch({ type: 'IS_PENDING' })
        setError(null)
        setSuccess(null)

        try {
            const docRef = doc(projectFirestore, col, id)
            const updatedDocument = await updateDoc(docRef, updates)

            dispatchIfNotCancelled({ type: 'UPDATED_DOCUMENT', payload: updatedDocument})

            setSuccess("Successfully updated your info!")

        } catch(err) {
            dispatchIfNotCancelled({type: 'ERROR', payload: err.message})
            return null
        }
    }

    // clean up function
    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { addDocument, updateDocument, deleteDocument, response, setError, error, success, setSuccess}
}