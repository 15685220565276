import { useParams } from 'react-router-dom'
import { useDocument } from '../../Hooks/useDocument'

import DashNav from '../../Components/DashNav'

import './Detail.css'
import Results from '../../Components/Results'

export default function Detail() {
  const { id } = useParams()
  const {error, document} = useDocument('lenders', id)

  if (error) {
    return <div>{error}</div>
  }

  if (!document) {
    return (
      <DashNav>
            <div className='dash-main-header'>
              <h2>Loading...</h2>
            </div>
        </DashNav>
    )
  }

  return (
        <DashNav>
            <div className='dash-main-header'>
                <h2>{document.title}</h2>
                <p>Lenders that matched your client's criteria.</p>
            </div>
            <div className='details-holder'>
              <Results lenders={document.lenderOptions} data={document.data} />
            </div>
        </DashNav>
  )
}