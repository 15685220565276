import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const CreditEvent = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">Any major credit event?</div>
            <p class="ae-form-paragraph">Has your client experienced a major credit event within the last 7 years?<br/></p>
        </div>
        </SlideFade>

        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
        <div class="ae-form-wrap">
                <div class="field-title">Any major credit event?</div>
                    <select
                        class="ae-form-field w-input"
                        id="majorCreditEvent"
                        name="majorCreditEvent"
                        value={data.majorCreditEvent}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>


                    {data.mcEvent && 
                        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
                            <div class="field-title">Briefly explain what happened:</div> </SlideFade>} 
                    {data.mcEvent && <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}><div className="ae-textarea-holder"><textarea
                        className="ae-form-field w-input"
                        id="majorCreditEventReason"
                        name="majorCreditEventReason"
                        onChange={handleChange}
                        value={data.majorCreditEventReason}
                        handleChange={handleChange}
                        cols={100}
                        rows={5}
                    /></div></SlideFade>}
            </div>
            </SlideFade>
        </div>
        </>        
    )

    return content
}
export default CreditEvent