import { useCollection } from '../../Hooks/useCollection'
import { useAuthContext } from '../../Hooks/useAuthContext'

import DashNav from '../../Components/DashNav'

import './History.css'
import HistoryList from './HistoryList'

export default function History() {
  const { user } = useAuthContext()
  const { documents, error } = useCollection(
    'lenders',
    ["user", "==", user.uid],
    ["createdAt", "desc"])

  return (
        <DashNav>
            <div className='dash-main-header'>
                <h2>History List</h2>
                <p>Your recent lender searches</p>
            </div>
            {error && <p>{error}</p>}
            {documents && <HistoryList lenders={documents} />}
        </DashNav>
  )
}