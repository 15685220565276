import { useState } from 'react'

import './Signup.css'
import NavBar from '../../Components/NavBar'
import { Link } from 'react-router-dom'
import { useSignup } from '../../Hooks/useSignup'

export default function Signup() {
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [license, setLicense] = useState('')
    const [password, setPassword] = useState('')
    const { signup, error, isPending } = useSignup()


    const handleSubmit = (e) => {
        e.preventDefault()
        signup(email, password, fName, lName, phone, license)
    }

  return (
    <div>
        <NavBar link="/login" title="Login" />

        <section className="container">
            <div className="content-holder">
                <div className="form-holder">
                    <h2 className="heading-6">Welcome to Account Executive</h2>
                    <div className="text-block-58">Create an account or <Link to="/login">login</Link>
                    </div>
                    <div className="w-form">
                        <form onSubmit={handleSubmit} id="email-form" name="email-form" data-name="Email Form" method="get" className="form" data-wf-page-id="6530514675abf06206167da9" data-wf-element-id="ab157335-7af5-bada-17e0-dd990e6c070e">
                            <div className="w-row">
                                <div className="form-column-left w-col w-col-6">
                                    <label className="ae-form-label">First Name</label>
                                    <input 
                                        type="text" className="ae-form-field w-input" onChange={(e) => setFName(e.target.value)}
                                        value={fName} 
                                        required />
                                </div>
                                <div className="form-column-right w-col w-col-6">
                                    <label className="ae-form-label">Last Name</label>
                                    <input 
                                    type="text" 
                                    className="ae-form-field w-input" onChange={(e) => setLName(e.target.value)}
                                    value={lName}
                                    required />
                                </div>
                            </div>
                            <label className="ae-form-label">Email</label>
                            <input 
                                type="email" className="ae-form-field w-input" 
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required />
                            
                            <label className="ae-form-label">Phone Number</label>
                            <input 
                            type="tel" 
                            className="ae-form-field w-input" 
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required />
                            
                            <label className="ae-form-label">License #</label>
                            <input type="text" className="ae-form-field w-input" 
                            onChange={(e) => setLicense(e.target.value)}
                            value={license}
                            required />
                            
                            <label className="ae-form-label">Password</label>
                            <input type="password" className="ae-form-field w-input" 
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required />
                            
                            <label className="ae-form-label">Confirm Password</label>
                            <input type="password" className="ae-form-field w-input" 
                            required />
                            
                            <div className="div-block-4">
                                <div className="text-block-59">By creating an account, you agree to our <Link to="#" className='signup-links'>Terms</Link> and have read and acknowledged the <Link to="#" className='signup-links'>Privacy Statement.</Link>
                                </div>
                            </div>
                            {!isPending && <button className="submit-button w-button">Sign Up</button>}
                            {isPending && <button className="submit-button w-button" disabled>Signing Up</button>}

                            {error && <div className="form-fail">
                                <p className='error-text'>{error}</p>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </section>
  </div>
  )
}
