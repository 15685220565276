import { useEffect, useState } from "react"
import useFormContext from "./useFormContext"

export const useFindLender = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { setGotResponse } = useFormContext()

    const findLender = async (query) => {
        setError(null)
        setIsPending(true)

        const controller = new AbortController()
        const timeoutID = setTimeout(() => controller.abort(), 40000)

        // sign user out
        try {
            const response = await fetch("https://www.stack-inference.com/run_deployed_flow?flow_id=6533d333a294f69115e9fe1d&org=f786f2cf-74ab-4206-bc7f-07a6ed5d9ddd", {
                headers: {'Authorization': 'Bearer e0b8f881-5f74-498b-bf93-dfe74f8a2393',
                'Content-Type': 'application/json'
            },
                method: "POST",
                body: JSON.stringify(query),
                signal: controller.signal
            })

            clearTimeout(timeoutID)

            if (response.ok) {
                const result = await response.json()
                if (!isCancelled) {
                    setIsPending(false)
                    setGotResponse(true)
                    setError(null)
                    return result
                }
            } else {
                console.log(`Server responded with status: ${response.status}`)
                setError('Our servers seemd to have timed out. Try again.')
            }

        } catch(err) {
            if (!isCancelled) {
                setGotResponse(false)
                setIsPending(false)

                if (err.name === 'AbortError') {
                    setError('Your request timed out. Please try again.')
                } else {
                    setError('We hit a snag communicating with the database. Please try again.')
                }
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return {findLender, error, isPending}
}