import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useState } from 'react';
import { useAuthContext } from './Hooks/useAuthContext';

import './App.css';

// pages & components
import Home from './Pages/Home/Home';
import History from './Pages/History/History';
import Settings from './Pages/Settings/Settings';
import FAQs from './Pages/FAQs/FAQs';
import Help from './Pages/Help/Help';
import Signup from './Pages/Signup/Signup'
import Login from './Pages/Login/Login';
import Detail from './Pages/History/Detail';

function App() {
  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route 
              path="/" 
              element={user ? <Home /> : <Login />} />
            <Route 
              path="/history" 
              element={user ? <History /> : <Login />} />
            <Route 
              path="/history/:id"
              element={user ? <Detail /> : <Login />} />
            <Route 
              path="/settings" 
              element={user ? <Settings /> : <Login />} />
            <Route 
              path="/faqs" 
              element={user ? <FAQs /> : <Login />} />
            <Route 
              path="/help" 
              element={user ? <Help /> : <Login />} />
            <Route 
              path="/signup" 
              element={user ? <Home /> : <Signup />} />
            <Route 
              path="/login" 
              element={user ? <Home /> : <Login />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
