import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const IncomeDoc = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What type of docs for income will your client provide?</div>
            <p class="ae-form-paragraph">Select the type of documents your client will provide to show proof of income.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Select income doc type:</div>
                    <select
                        class="ae-form-field w-input"
                        id="incomeDocType"
                        name="incomeDocType"
                        value={data.incomeDocType}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="W2">W2</option>
                        <option value="Paystub">Paystub</option>
                        <option value="1 Year Full Documentation">1 Year Full Documentation</option>
                        <option value="2 Year Full Documentation">2 Year Full Documentation</option>
                        <option value="12 Month Bank Statements">12 Month Bank Statements</option>
                        <option value="24 Month Bank Statements">24 Month Bank Statements</option>
                        <option value="Asset Depletion">Asset Depletion</option>
                        <option value="Asset Qualifier">Asset Qualifier</option>
                        <option value="DSCR">DSCR</option>
                        <option value="No Ratio DSCR">No Ratio DSCR</option>
                        <option value="Foreign Income">Foreign Income</option>
                        <option value="1099">1099</option>
                        <option value="P&L Only">P&L Only</option>
                    </select>
            </div>
        </SlideFade>
        </div>
        </>
    )

    return content
}
export default IncomeDoc