import useFormContext from "../Hooks/useFormContext"
import FormInputs from "./FormInputs"
import { 
    CircularProgress, 
    Button,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
 } from "@chakra-ui/react"
import { useFindLender } from '../Hooks/useFindLender'
import { useAuthContext } from '../Hooks/useAuthContext'
import { useFirestore } from '../Hooks/useFirestore'
import { useRef, useState } from "react"
// import { platform } from "os"

const Form = () => {

    const toast  = useToast()
    const toastID = 'test-toast'

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const cancelRef = useRef()

    const { user } = useAuthContext()

    const {
        page,
        setPage,
        data,
        setData,
        title,
        stepCount,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide,
        progress,
        gotResponse,
        setGotResponse,
        stackResponse,
        setStackResponse,
        handleTitleChange,
        searchTitle,
        setJsonError
    } = useFormContext()

    const handlePrev = () => setPage(prev => prev - 1)

    const handleNext = () => setPage(prev => prev + 1)

    const { findLender, isPending } = useFindLender()
    const [searchDidSave, setSearchDidSave] = useState(false)

    let aiQuery = ``

    const { addDocument, 
        error, 
        setError,
        success,
        setSuccess
    } = useFirestore('lenders')

    const showErrorToast = () => {
        if (!toast.isActive(toastID)) {
            toast({
                toastID,
                title: `${error}`,
                status: 'error',
                isClosable: true,
            })
        }
    }

    const showSuccessToast = () => {
        if (!toast.isActive(toastID)) {
            toast({
                toastID,
                title: `${success}`,
                status: 'success',
                isClosable: true,
            })
        }
    }

    const saveSearch = () => {
        console.log('search saved')

        addDocument({
            user: user.uid,
            lenderOptions: stackResponse,
            title: searchTitle,
            data: data
        })

        if (!error) {
            setSearchDidSave(true)
        }

    }

    const handleSubmit = e => {
        setError(null)
        setJsonError(null)
        e.preventDefault()
        console.log(JSON.stringify(data))

        if (!gotResponse) {

            if (data.residency === 'US Citizen' && data.majorCreditEvent === 'No') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));

                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows: 
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency === 'Permanent Resident Alien' && data.majorCreditEvent === 'No') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));


                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows:
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency === 'US Citizen' && data.majorCreditEvent === 'Yes') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));


                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, major credit event last 7 years: ${data.majorCreditEventReason}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows:  
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                

                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency ===    'Permanent Resident Alien' && data.majorCreditEvent === 'Yes') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));

                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, major credit event last 7 years: ${data.majorCreditEventReason}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows: 
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                

                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency !== 'US Citizen' && data.majorCreditEvent === 'No') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));


                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Visa type: ${data.nonCitizen}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows: 
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`


                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency !== 'Permanent Resident Alien' && data.majorCreditEvent === 'No') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));


                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Visa type: ${data.nonCitizen}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows:  
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    mininumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                

                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency !== 'US Citizen' && data.majorCreditEvent === 'Yes') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));

                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Visa type: ${data.nonCitizen}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, major credit event last 7 years: ${data.majorCreditEventReason}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows: 
                {loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`

                
                
                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            } else if (data.residency !== 'Permanent Resident Alien' && data.majorCreditEvent === 'Yes') {

                let budget = data.budgetAmount
                let budgetWithoutDollar = parseFloat(budget.replace('$', ''));

                let appraised = data.appraisedValue
                let appraisedWithoutDollar = parseFloat(appraised.replace('$', ''));

                let calculateLTV = (budgetWithoutDollar / appraisedWithoutDollar) * 100
                let ltvTwoDecimal = calculateLTV.toFixed(2)
                data.ltv = `${ltvTwoDecimal}%`

                aiQuery = `Identify all loan options that align with the following criteria: Loan Amount: ${data.budgetAmount}, LTV: ${data.ltv}, FICO Score: ${data.creditScore}, Property Type: ${data.propertyType}, Occupancy: ${data.occupancy}, Citizenship status: ${data.residency}, Visa type: ${data.nonCitizen}, Income document type: ${data.incomeDocType}, DTI: ${data.debt}, major credit event last 7 years: ${data.majorCreditEventReason}, First-time Homebuyer: ${data.firstTime}. Note: For the website, use only the base URL. Exclude DTI and First-time Homebuyer status from the final loan options. The response should be formatted as JSON, as follows: 
                { loanOptions: [{
                    lender: '',
                    website: '',
                    maxLoanAmount: '',
                    ltv: '',
                    occupancy: '',
                    incomeType: '',
                    minimumFico: '',
                    }
                    //Additional loan options here
                  ]}`
                
                
                
                console.log("Calculated LTV: " + data.ltv)
                console.log("AI Query is: " + aiQuery)
            }

            //continue with find lender
            findLender({"in-0": aiQuery, "user_id": user.uid}).then((response) => {
                handleResponse(response)
            })
            .catch(error => {
                handleError(error)
            })
        } else {
            //reset
            setPage(0) 
            setGotResponse(false)
            setStackResponse([])
            setSearchDidSave(false)
            setError(null)
            setSuccess(null)
            setJsonError(null)
            aiQuery = ``
            setData({
                budgetAmount: "",
                appraisedValue: "",
                creditScore: "",
                propertyType: "",
                occupancy: "",
                residency: "",
                nonCitizen: "",
                incomeDocType: "",
                debt: "",
                alien: false,
                majorCreditEvent: "",
                majorCreditEventReason: "",
                mcEvent: false,
                paymentHistory: "",
                firstTime: ""
            })
        }

        function handleResponse(response) {

            try {
                const stringified = JSON.stringify(response);
                const parsed = JSON.parse(stringified);
                const secondParse = JSON.parse(parsed['out-0']);
                const loanOptions = secondParse.loanOptions;
            
                console.log(loanOptions);
            
                setStackResponse(loanOptions);
              } catch (error) {
                // Handle parsing errors
                handleError(error);
              }
        }

        function handleError(error) {
            console.error(error);
            setGotResponse(true);
            setJsonError('Something went wrong. Please try again or contact support if this continues.');
          }
    }

    const content = (

        <div class="ae-form-holder">
            <div class="w-form">
                <form id="email-form" onSubmit={handleSubmit}>
                    <div class="ae-form-content-wrap">
                        <div class="ae-form-top-holder">
                            <div class="ae-form-title-holder">
                                <div class="text-block">{title[page]}</div>
                            </div>
                            <div class="ae-form-details-right">
                            <CircularProgress size='16px' value={progress[page]} className="counter-image" />
                            <div class="text-counter">{stepCount[page]} / 12</div>
                            </div>
                        </div>

                        <FormInputs />

                        <div class={`ae-form-button-holder ${prevHide}`}>
                            <div class={`ae-form-prev-btn ${prevHide}`}>{!isPending &&<img alt="" src="/img/arrow.svg" class={`arrow ${prevHide}`}/>}

                            {!isPending && <button type="button" class={`previous-button ${prevHide}`} onClick={handlePrev} disabled={disablePrev} >Previous</button>}
                            </div>

                            <div class="ae-form-next-btn">

                            {gotResponse && <button type="button" class='save-button' onClick={onOpen} disabled={searchDidSave}>Save Search</button>}

                            {error && showErrorToast()}
                            {success && showSuccessToast()} 

                            <Modal
                                isCentered
                                motionPreset="slideInBottom"
                                leastDestructiveRef={cancelRef}
                                initialFocusRef={initialRef}
                                isOpen={isOpen}
                                onClose={onClose}
                            >
                                <ModalOverlay 
                                    bg='blackAlpha.300'
                                    backdropFilter='blur(10px)'
                                />

                                <ModalContent>

                                    <ModalHeader>Save</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        Add a helpful title to this search.
                                        <FormControl mt={5}>
                                            <FormLabel>Title:</FormLabel>
                                            <Input focusBorderColor="#0084ff" ref={initialRef} placeholder='Your title here' onChange={handleTitleChange}
                                            value={searchTitle} />
                                        </FormControl>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                        </Button>
                                        
                                        <Button bg='#0084ff' textColor='#ffffff' ml={3} onClick={() =>{saveSearch(); onClose()}}>
                                        Save
                                        </Button>
                                    </ModalFooter>

                                </ModalContent>

                            </Modal>


                            {!isPending && <button type="submit" class={`next-button ${submitHide}`} disabled={!canSubmit} >{!gotResponse ? "Find a Lender!" : "Search Again"}</button>}

                            {isPending && <CircularProgress isIndeterminate color='#0084FF' size='44px' className={submitHide} />}

                            <button type="button" class={`next-button ${nextHide}`} onClick={handleNext} disabled={disableNext} >Next Step</button>

                            {/* <button type="button" class={`next-button ${resetHide}`} onClick={handleReset} >Find Another</button> */}

                            </div>

                        </div>
                        <div class="line-bottom"></div>

                    </div>
                </form>

            </div>
        </div>

        /* <form className="form flex-col" onSubmit={handleSubmit}>
            <header className="form-header">
                <h2>{title[page]}</h2>
                <div className="button-container">
                    <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

                    <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

                    <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button>
                </div>
            </header>

            <FormInputs />

        </form> */
    )

    return content
}
export default Form