import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Budget = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What is the loan amount?</div>
            <p class="ae-form-paragraph">The amount your client looking to take out for a mortgage and borrow<br/></p>
        </div>
        </SlideFade>

        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Enter desired loan amount</div>
                <input
                    type="text" 
                    class="ae-form-field w-input" 
                    id="budgetAmount"
                    name="budgetAmount"
                    value={data.budgetAmount}
                    onChange={handleChange} 
                />
            </div>
            </SlideFade>
        </div>
        </>        
    )

    return content
}
export default Budget