import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Appraised = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What is the appraised value?</div>
            <p class="ae-form-paragraph">The amount the property appraised for.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                    <div class="field-title">Enter appraised value</div>
                <input
                    type="text" 
                    class="ae-form-field w-input" 
                    id="appraisedValue"
                    name="appraisedValue"
                    value={data.appraisedValue} 
                    onChange={handleChange} 
                />
            </div>
            </SlideFade>
        </div>
        </>
    )

    return content
}
export default Appraised