import { Link } from 'react-router-dom'
import './HistoryList.css'

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure, } from '@chakra-ui/react'
import { useRef } from 'react'

export default function HistoryList({ lenders }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const handleDelete = () => {
    console.log('Delete object')
  }

  return (
    <div className='history-table-holder'>
      <div className='history-table-top-holder'>
        <div className='table-control-holder'>
          {/* this will be the filter */}
        </div>
      </div>
      {lenders.length === 0 && <p className='history-table-row'>You have no recent searches yet...</p>}
          {lenders.map(lender => (
              <Link to={`/history/${lender.id}`} className='history-table-row  w-inline-block' key={lender.id}>
                <div className='table-row-title-holder'>
                  <div className='table-row-title-text'>
                    {lender.title}
                  </div>
                </div>
                <div className='table-row-actions-holder'>
                  <img src="/img/calendar-icon.svg" loading="lazy" width="16" alt="calendar icon" />
                  <div className='table-row-date-text'>
                    {lender.createdAt.toDate().toDateString()}
                  </div>
                  <Link onClick={onOpen} className='table-row-delete-icon'>
                  <svg width="16" height="16" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39 39H45.5V78H39V39ZM58.5 39H65V78H58.5V39Z" fill="currentColor"/>
                    <path d="M13 19.5V26H19.5V91C19.5 92.7239 20.1848 94.3772 21.4038 95.5962C22.6228 96.8152 24.2761 97.5 26 97.5H78C79.7239 97.5 81.3772 96.8152 82.5962 95.5962C83.8152 94.3772 84.5 92.7239 84.5 91V26H91V19.5H13ZM26 91V26H78V91H26ZM39 6.5H65V13H39V6.5Z" fill="currentColor"/>
                  </svg>
                  </Link>
                </div>
              </Link>
          ))}
          <AlertDialog
                        motionPreset='slideInBottom'
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                        isOpen={isOpen}
                        isCentered
                    >
                        <AlertDialogOverlay 
                            bg='blackAlpha.300'
                            backdropFilter='blur(10px)'

                        />

                        <AlertDialogContent>
                            <AlertDialogHeader>Delete Record</AlertDialogHeader>
                            <AlertDialogCloseButton />

                            <AlertDialogBody>
                                Are you sure you want to delete this record? You can't get it back once deleted.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                                </Button>
                                <Button colorScheme='red' ml={3} onClick={() => {handleDelete(); onClose()}}>
                                Yes
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>

                    </AlertDialog>
    </div>
  )
}
