import { useEffect, useState } from 'react'
import { projectAuth, projectFirestore } from '../Firebase/config'
import { useAuthContext } from './useAuthContext'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, addDoc } from 'firebase/firestore'

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const signup = async (email, password, fName, lName, phone, license) => {
        setError(null)
        setIsPending(true)

        try {
            //sign up user
            const res = await createUserWithEmailAndPassword(projectAuth, email, password)
            

            if (!res) {
                throw new Error('Could not complete signup')
            }

            // add first name, last name, phone, and license to db
            await addDoc(collection(projectFirestore, 'userInfo'), {
                firstName: fName,
                lastName: lName,
                phone: phone,
                license: license,
                user: res.user.uid
            })

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: res.user })

            // update state
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }

        } catch (err) {
            if (!isCancelled) {
                console.log(err.code)
                console.log(err.message)
                if (err.code === 'auth/email-already-exists') {
                    setError('This email is already in use.')
                    setIsPending(false)
                } else if (err.code === 'auth/invalid-email') {
                    setError('This is an invalid email address, choose a different one.')
                    setIsPending(false)
                } else if (err.code === 'auth/weak-password') {
                    setError('The password you chose is not strong enough. Use at least 6 characters.')
                    setIsPending(false)
                } else if (err.code === 'auth/invalid-password') {
                    setError('The password you chose is not strong enough. Use at least 6 characters.')
                    setIsPending(false)
                } else {
                    setError('This is embarassing, something went wrong. Please try again.')
                    setIsPending(false)
                }
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { error, isPending, signup }
}