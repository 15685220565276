import { Button, CircularProgress, useToast } from '@chakra-ui/react'
import DashNav from '../../Components/DashNav'
import { updateEmail, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

import './Settings.css'
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../Hooks/useAuthContext';
import { useCollection } from '../../Hooks/useCollection';
import { useFirestore } from '../../Hooks/useFirestore';
import { setDoc } from 'firebase/firestore';

export default function Settings() {
    const { user } = useAuthContext()
    const { documents, error } = useCollection(
      'userInfo',
      ["user", "==", user.uid]);

    const { updateDocument, response } = useFirestore('userInfo')

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [license, setLicense] = useState('');
    const [personalIsPending, setPersonalIsPending] = useState(false)

    const [emailInputChange, setEmailInputChange] = useState(false)
    const [newEmail, setNewEmail] = useState(user.email);
    const [emailAuth, setEmailAuth] = useState(false)
    const [emailPass, setEmailPass] = useState('')
    const [emailIsPending, setEmailIsPending] = useState(false)

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPending, setIsPending] = useState(false)

    const [docID, setDocID] = useState('')
   
    const toast  = useToast()
    const toastID = 'test-toast'

    const handleCurrentPasswordChange = (event) => {
      setCurrentPassword(event.target.value);
    };
  
    const handleNewPasswordChange = (event) => {
      setNewPassword(event.target.value);
    };
  
    const handleConfirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value);
    };

    const handleEmailChange = (event) => {
      setEmailInputChange(true)
      setNewEmail(event.target.value);
    };

    const handleEmailPassChange = (event) => {
      setEmailPass(event.target.value)
    }

    // Personal info update
    const updatePersonalInfo = () => {

      setPersonalIsPending(true)

      updateDocument(docID, {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        license: license
      })

    }

    // watch for changes to personal info
    useEffect(() => {
      if (response.error) {
          setPersonalIsPending(false)
          showErrorToast("Did not update, try again.")
      }
      if (response.success) {
          setPersonalIsPending(false)
          showSuccessToast("Successfully updated your info!")
      }
    }, [response])

    //Email update
    const handleEmailUpdate = () => {
      setEmailIsPending(true)
      if (!newEmail.trim()) {
        showErrorToast("Email field can't be empty")
        setEmailIsPending(false)
        return; // Stop the function if any field is empty
      }

      updateEmail(user, newEmail).then(() => {
        // Email updated!
        showSuccessToast("Email updated successfully!")
      }).catch((error) => {
        // An error occurred
        if (error.code === "auth/requires-recent-login") {
          //need to re-authenticate
          setEmailAuth(true)
        }
      });

    }

    //Handle email update with password
    const handleEmailUpdatePassword = () => {
      if (!emailPass.trim() || !newEmail.trim()) {
        showErrorToast("Password/Email field can't be empty")
        setEmailIsPending(false)
        return; // Stop the function if any field is empty
      }

      reauthenticateAndRetryEmailUpdate()

    }


    const reauthenticateAndRetryEmailUpdate = () => {
      const credential = EmailAuthProvider.credential(user.email, emailPass);

      reauthenticateWithCredential(user, credential).then(() => {
        // User re-authenticated, now try updating the password again
        setEmailAuth(false)
        updateEmail(user, newEmail).then(() => {
          setEmailIsPending(false)
          showSuccessToast("Password updated successfully!")
        }).catch((error) => {
          setEmailIsPending(false)
          console.log(error.message);
          showErrorToast("Something went wrong, try again.")
        });
      }).catch((error) => {
        setEmailIsPending(false)
        if (error.code === "auth/wrong-password") {
          showErrorToast("The current password is incorrect")
        } else {
          setEmailAuth(true)
          console.log("Re-authentication failed: " + error.message);
          showErrorToast("Re-authentication failed, please try again.")
        }
      });
    }


    const reauthenticateAndRetryPasswordUpdate = () => {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
  
      reauthenticateWithCredential(user, credential).then(() => {
        // User re-authenticated, now try updating the password again
        updatePassword(user, newPassword).then(() => {
          setIsPending(false)
          showSuccessToast("Password updated successfully!")
          setNewPassword('');
          setConfirmPassword('');
          setCurrentPassword('');
        }).catch((error) => {
          setIsPending(false)
          console.log(error.message);
          showErrorToast("Something went wrong, try again.")
        });
      }).catch((error) => {
        setIsPending(false)
        if (error.code === "auth/wrong-password") {
          showErrorToast("The current password is incorrect")
        } else {
          console.log("Re-authentication failed: " + error.message);
          showErrorToast("Re-authentication failed, please try again.")
        }
      });
    };
  
    const handlePasswordUpdate = () => {
      setIsPending(true)
      if (!newPassword.trim() || !confirmPassword.trim() || !currentPassword.trim()) {
        showErrorToast("Password fields can't be empty")
        setIsPending(false)
        return; // Stop the function if any field is empty
      }

      if (newPassword === confirmPassword) {
        updatePassword(user, newPassword).then(() => {
          // Update successful
          setCurrentPassword('')
          setNewPassword('')
          setConfirmPassword('')
          
          setIsPending(false)
          showSuccessToast("Password updated successfully!")
        }).catch((error) => {
          // An error occurred
          console.log(error.code)
          if (error.code === "auth/requires-recent-login") {
            reauthenticateAndRetryPasswordUpdate()
          }
          showErrorToast("Something went wrong, try again.")
          setIsPending(false)
        });
      } else {
        setIsPending(false)
        showErrorToast("Passwords do not match")
      }
    }

    const showSuccessToast = (message) => {
      if (!toast.isActive(toastID)) {
          toast({
              toastID,
              title: message,
              status: 'success',
              isClosable: true,
          })
      }
    }

    const showErrorToast = (message) => {
      if (!toast.isActive(toastID)) {
          toast({
              toastID,
              title: message,
              status: 'error',
              isClosable: true,
          })
      }
    }

    //get personal info
    useEffect(() => {
      if (documents && documents.length > 0) {
          // Assuming you want to populate with the first document's data
          const doc = documents[0];
          setFirstName(doc.firstName || '');
          setLastName(doc.lastName || '');
          setPhone(doc.phone || '');
          setLicense(doc.license || '');
          setDocID(doc.id || '')
      }
    }, [documents]);

  return (
    <DashNav>
      <div className='dash-main-header'>
        <h2 className='history-heading'>Settings</h2>
        <p>You can edit your account details here</p>
      </div>

      <div className='settings-holder'>
        <div className='settings-info'>
          <div className='settings-info-title'>Personal Info</div>
          <div className='field-holder'>
            <div>Name</div>
            <div className='input-holder mobile-input'>
              <input className='settings-input w-input' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
              <input className='settings-input margin-left-10 w-input' value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
          </div>

          <div className='field-holder'>
            <div>License #</div>
            <div className='input-holder mobile-input'>
              <input className='settings-input w-input' value={license} onChange={(e) => setLicense(e.target.value)} />
            </div>
          </div>

          <div className='field-holder'>
            <div>Phone</div>
            <div className='input-holder mobile-input'>
              <input className='settings-input w-input' value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
          </div>

          <div className='button-holder'>
            {!personalIsPending && <Button class="settings-submit w-button" onClick={updatePersonalInfo}>Update Info</Button>}
            {personalIsPending && <CircularProgress isIndeterminate color='#0084FF' size='44px'/>}
          </div>

        </div>


      

        <div className='settings-info'>
          <div className='settings-info-title'>Account Specific</div>

          <div className='field-holder'>
            <div>Email</div>
            <div className='input-holder mobile-input'>
              <input className='settings-input w-input' value={newEmail} onChange={handleEmailChange} />
            </div>
          </div>

          {emailAuth && <div className='field-holder'>
            <div>Password</div>
            <div className='input-holder mobile-input'>
              <input className='settings-input w-input' type='password' placeholder='Enter your password to update your email.' value={emailPass} onChange={handleEmailPassChange}/>
            </div>
          </div>}

          <div className='button-holder'>
            {!emailIsPending && <button disabled={!emailInputChange} class="settings-submit w-button" onClick={emailAuth ? handleEmailUpdatePassword : handleEmailUpdate}>Update Email</button>}
            {emailIsPending && <CircularProgress isIndeterminate color='#0084FF' size='44px'/>}
          </div>

        </div>



        <div className='settings-info'>
          <div className='settings-info-title'>Change Password</div>

          <div className='field-holder'>
            <div>Current Password</div>
            <div className='input-holder mobile-input'>
              <input type='password' placeholder='Current password' className='settings-input w-input' value={currentPassword} onChange={handleCurrentPasswordChange} />
            </div>
          </div>

          <div className='field-holder'>
            <div>New Password</div>
            <div className='input-holder mobile-input'>
              <input type='password' placeholder='New password' className='settings-input w-input' value={newPassword} onChange={handleNewPasswordChange} />
            </div>
          </div>

          <div className='field-holder'>
            <div>Confirm Password</div>
            <div className='input-holder mobile-input'>
              <input type='password' placeholder='Confirm new password' className='settings-input w-input' value={confirmPassword} onChange={handleConfirmPasswordChange} />
            </div>
          </div>

          <div className='button-holder'>
            {!isPending && <Button class="settings-submit w-button" onClick={handlePasswordUpdate}>Update Password</Button>}
            {isPending && <CircularProgress isIndeterminate color='#0084FF' size='44px'/>}
          </div>

        </div>


      </div>
    </DashNav>
  )
}
