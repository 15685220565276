import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Occupancy = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
            <div class="ae-form-heading">
                <div class="form-section-title">How will your client occupy the property?</div>
                <p class="ae-form-paragraph">Will they be using this as an investment property or making this their new primary home? Make your selection below.<br/></p>
            </div>
        </SlideFade>

        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Select an occupancy:</div>
                    <select
                        class="ae-form-field w-input"
                        id="occupancy"
                        name="occupancy"
                        value={data.occupancy}
                        onChange={handleChange}
                        >
                        <option value="" disabled></option>
                        <option value="Primary">Primary</option>
                        <option value="Secondary">Secondary</option>
                        <option value="Investment">Investment</option>
                    </select>
            </div>
        </SlideFade>
        </div>
        </>        
    )

    return content
}
export default Occupancy