import { useEffect, useState } from "react"
import { projectAuth } from '../Firebase/config'
import { useAuthContext } from './useAuthContext'
import { signOut } from "firebase/auth"

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()

    const logout = async () => {
        setError(null)
        setIsPending(true)

        // sign user out
        try {
            await signOut(projectAuth)

            //dispatch logout action
            dispatch({type: 'LOGOUT'})

            // update state
            if (!isCancelled) {
                setIsPending(false)
                setError(null)
            }
        } catch(err) {
            if (!isCancelled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return {logout, error, isPending}
}
