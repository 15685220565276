import useFormContext from "../Hooks/useFormContext"

// pages and components
import Budget from "./Form/Budget"
import Appraised from "./Form/Appraised"
import Credit from "./Form/Credit"
import Property from "./Form/Property"
import Occupancy from "./Form/Occupancy"
import Residency from "./Form/Residency"
import IncomeDoc from "./Form/IncomeDoc"
import Debt from "./Form/Debt"
import CreditEvent from "./Form/CreditEvent"
import PaymentHistory from "./Form/PaymentHistory"
import FindLender from "./Form/FindLender"
import FirstTime from "./Form/FirstTime"


const FormInputs = () => {
    
    const { page } = useFormContext()

    const display = {
        0: <Budget />,
        1: <Appraised />,
        2: <Credit />,
        3: <Property />,
        4: <Occupancy />,
        5: <Residency />,
        6: <IncomeDoc />,
        7: <Debt />,
        8: <CreditEvent />,
        9: <PaymentHistory />,
        10: <FirstTime />,
        11: <FindLender />
    }

  const content = (
    <>
        {display[page]}
    </>
  )

  return content
}

export default FormInputs