import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const Residency = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">What is the residency status of your client?</div>
            <p class="ae-form-paragraph">Please select from the drop down that best describes your client's current residency status in the United States.<br/></p>
        </div>
        </SlideFade>

        <div class="ae-form-field-holder">

        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
        <div class="ae-form-wrap">
                <div class="field-title">Select a residency status:</div>
                    <select
                        class="ae-form-field w-input"
                        id="residency"
                        name="residency"
                        value={data.residency}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="US Citizen">US Citizen</option>
                        <option value="Permanent Resident Alien">Permanent Resident Alien</option>
                        <option value="Non-Permanent Resident Alien with FICO">Non-Permanent Resident Alien with FICO</option>
                        <option value="Non-Permanent Resident Alien without FICO">Non-Permanent Resident Alien without FICO</option>
                        <option value="ITIN Borrower with FICO">ITIN Borrower with FICO</option>
                        <option value="ITIN Borrower without FICO">ITIN Borrower without FICO</option>
                        <option value="Foreign National">Foreign National</option>
                    </select>


                    {data.alien && <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}> <div class="field-title">Select a visa type:</div></SlideFade>}
                    {data.alien && <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}> 
                    <div className="ae-textarea-holder">
                    <select
                        class="ae-form-field w-input"
                        id="nonCitizen"
                        name="nonCitizen"
                        value={data.nonCitizen}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        <option value="No Visa">No Visa</option>
                        <option value="A1">A1</option>
                        <option value="A2">A2</option>
                        <option value="A3">A3</option>
                        <option value="B1">B1</option>
                        <option value="B2">B2</option>
                        <option value="E1">E1</option>
                        <option value="E2">E2</option>
                        <option value="E3">E3</option>
                        <option value="F1">F1</option>
                        <option value="G1">G1</option>
                        <option value="G2">G2</option>
                        <option value="G3">G3</option>
                        <option value="G4">G4</option>
                        <option value="G5">G5</option>
                        <option value="H1">H1</option>
                        <option value="H2">H2</option>
                        <option value="H3">H3</option>
                        <option value="I">I</option>
                        <option value="J1">J1</option>
                        <option value="J2">J2</option>
                        <option value="L1">L1</option>
                        <option value="NATO 1-7">NATO 1-7</option>
                        <option value="O1">O1</option>
                        <option value="O2">O2</option>
                        <option value="P1">P1</option>
                        <option value="P2">P2</option>
                        <option value="TN (NAFTA)">TN (NAFTA)</option>
                        <option value="Visa Waiver Program">Visa Waiver Program</option>
                    </select></div></SlideFade>}
            </div>
            </SlideFade>
        </div>
        </>        
    )

    return content
}
export default Residency