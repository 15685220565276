import useFormContext from "../../Hooks/useFormContext"
import { SlideFade } from "@chakra-ui/react"

import '../../Styles/AEWebflow.css'

const PaymentHistory = () => {

    const { data, handleChange } = useFormContext()

    const content = (
        <>
        <SlideFade in={true} offsetY='20px' transition={{ enter: {duration: 0.8}}}>
        <div class="ae-form-heading">
            <div class="form-section-title">Your clients payment history</div>
            <p class="ae-form-paragraph">Select the best option that portrays your client's recent payment history.<br/></p>
        </div>
        </SlideFade>
        <div class="ae-form-field-holder">
        <SlideFade in={true} offsetY='20px' transition={{ enter: {delay: 0.2, duration: 0.8}}}>
            <div class="ae-form-wrap">
                <div class="field-title">Select payment history:</div>
                    <select
                        class="ae-form-field w-input"
                        id="paymentHistory"
                        name="paymentHistory"
                        value={data.paymentHistory}
                        onChange={handleChange}
                    >
                        <option value="" disabled></option>
                        <option value="No late payments in the last 48 months">No late payments in the last 48 months</option>
                        <option value="No Payment History">No Payment History</option>
                        <option value="1x30, 37-48 Months Ago">1x30, 37-48 Months Ago</option>
                        <option value="1x30, 25-36 Months Ago">1x30, 25-36 Months Ago</option>
                        <option value="1x30, 13-24 Months Ago">1x30, 13-24 Months Ago</option>
                        <option value="2x30, 13-24 Months Ago">2x30, 13-24 Months Ago</option>
                        <option value="1x30 in Last 12 Months">1x30 in Last 12 Months</option>
                        <option value="2x30 in Last 12 Months">2x30 in Last 12 Months</option>
                        <option value="1x60 in Last 12 Months">1x60 in Last 12 Months</option>
                    </select>
            </div>
        </SlideFade>
        </div>
        </>
    )

    return content
}
export default PaymentHistory