import { useEffect, useState } from "react"
import { projectFirestore } from "../Firebase/config"
import { collection, onSnapshot, doc } from "firebase/firestore"

export const useDocument = (c, id) => {
    const [document, setDocument] = useState(null)
    const [error, setError] = useState(null)

    //realtime data for document
    useEffect(() => {
        const ref = doc(projectFirestore, c, id)

        const unsub = onSnapshot(ref, (snapshot) => {
            setDocument({...snapshot.data(), id: snapshot.id})
            setError(null)
        }, (err) => {
            console.log(err.message)
            setError('Failed to get the specified item')
        })

        return () => unsub()

    }, [c, id])

    return {document, error}
}