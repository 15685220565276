import DashNav from '../../Components/DashNav'

import './FAQs.css'

export default function FAQs() {
  return (
    <DashNav>
        <h2>FAQs</h2>
    </DashNav>
  )
}
