import { createContext, useState, useEffect } from "react"

const FormContext = createContext({})

export const FormProvider = ({ children }) => {

    const title = {
        0: 'Budget',
        1: 'Value',
        2: 'Credit',
        3: 'Property',
        4: 'Occupancy',
        5: 'Residency',
        6: 'Income',
        7: 'Debt',
        8: 'Credit Event',
        9:  'Payment History',
        10: 'Homebuyer',
        11: 'Find Lender'
    }

    const progress = {
        0: '0',
        1: '9',
        2: '18',
        3: '27',
        4: '36',
        5: '45',
        6: '54',
        7: '63',
        8: '72',
        9: '81',
        10: '90',
        11: '100'
    }

    const stepCount = {
        0: '1',
        1: '2',
        2: '3',
        3: '4',
        4: '5',
        5: '6',
        6: '7',
        7: '8',
        8: '9',
        9: '10',
        10: '11',
        11: '12'
    }

    const [stackResponse, setStackResponse] = useState([])
    const [gotResponse, setGotResponse] = useState(false)
    const [jsonError, setJsonError] = useState(null)

    const [page, setPage] = useState(0)

    const [searchTitle, setSearchTitle] = useState('')

    const [data, setData] = useState({
        budgetAmount: "",
        appraisedValue: "",
        ltv: "",
        creditScore: "",
        propertyType: "",
        occupancy: "",
        residency: "",
        nonCitizen: "",
        incomeDocType: "",
        debt: "",
        alien: false,
        majorCreditEvent: "",
        majorCreditEventReason: "",
        mcEvent: false,
        paymentHistory: "",
        firstTime: ""
    })

    // useEffect(() => {
    //     if (data.sameAsBilling) {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: prevData.billFirstName,
    //             shipLastName: prevData.billLastName,
    //             shipAddress1: prevData.billAddress1,
    //             shipAddress2: prevData.billAddress2,
    //             shipCity: prevData.billCity,
    //             shipState: prevData.billState,
    //             shipZipCode: prevData.billZipCode
    //         }))
    //     } else {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: "",
    //             shipLastName: "",
    //             shipAddress1: "",
    //             shipAddress2: "",
    //             shipCity: "",
    //             shipState: "",
    //             shipZipCode: ""
    //         }))
    //     }
    // }, [data.sameAsBilling])

    useEffect(() => {
        if (data.residency === "" || data.residency === "US Citizen" || data.residency === "Permanent Resident Alien") {
            setData(prevData => ({
                ...prevData,
                alien: false
            }))
        } else {
            setData(prevData => ({
                ...prevData,
                alien: true
            }))
        }

    }, [data.residency])

    useEffect(() => {
        if (data.majorCreditEvent === "Yes") {
            setData(prevData => ({
                ...prevData,
                mcEvent: true
            }))
        } else {
            setData(prevData => ({
                ...prevData,
                mcEvent: false
            }))
        }

    }, [data.majorCreditEvent])

    const handleChange = e => {

        const type = e.target.type

        const name = e.target.name

        let value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        // Check if the input is 'budgetAmount'
        if (name === "budgetAmount") {
            // Remove existing formatting and add new formatting
            value = formatCurrencyInput(value);
        } else if (name === "appraisedValue") {
            // Remove existing formatting and add new formatting
            value = formatCurrencyInput(value);
        } else if (name === "debt") {
            value = formatPercentageInput(value)
        }

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))

    }

    function formatPercentageInput(inputValue) {
        // Remove existing percent sign, commas, and non-numeric characters
        inputValue = inputValue.replace(/%/g, '').replace(/,/g, '').replace(/[^0-9]/g, '');
    
        // Append a percent sign if there's a value
        if (inputValue) {
            // Format the number with commas
            inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            
            inputValue += '%';
        }
        return inputValue;
    }

    function formatCurrencyInput(inputValue) {
        // Remove existing commas, non-numeric characters, and dollar sign
        inputValue = inputValue.replace(/,/g, '').replace(/[^0-9]/g, '').replace(/^\$/, '');
    
        // Only add formatting if there's a value
        if (inputValue) {
            // Format the number with commas
            inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
            // Prepend a dollar sign
            inputValue = '$' + inputValue;
        }
        return inputValue;
    }

    const handleTitleChange = e => {

        const value = e.target.value

        console.log(value)

        setSearchTitle(value)
    }

    const { 
        nonCitizen,
        majorCreditEventReason,
        mcEvent,
        alien,
        ltv,
        ...requiredInputs } = data

    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    const canNextPage1 = Object.keys(data)
        .filter(key => key.startsWith('budget'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage2 = Object.keys(data)
        .filter(key => key.startsWith('appraised') && key !== 'ltv')
        .map(key => data[key])
        .every(Boolean)

    const canNextPage3 = Object.keys(data)
        .filter(key => key.startsWith('credit'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage4 = Object.keys(data)
        .filter(key => key.startsWith('property'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage5 = Object.keys(data)
        .filter(key => key.startsWith('occupancy'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage6 = Object.keys(data)
        .filter(key => key.startsWith('residency') && key !== 'nonCitizen')
        .map(key => data[key])
        .every(Boolean)

    const canNextPage7 = Object.keys(data)
        .filter(key => key.startsWith('incomeDoc'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage8 = Object.keys(data)
        .filter(key => key.startsWith('debt'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage9 = Object.keys(data)
        .filter(key => key.startsWith('major') && key !== 'majorCreditEventReason')
        .map(key => data[key])
        .every(Boolean)

    const canNextPage10 = Object.keys(data)
        .filter(key => key.startsWith('payment'))
        .map(key => data[key])
        .every(Boolean)

    const canNextPage11 = Object.keys(data)
        .filter(key => key.startsWith('first'))
        .map(key => data[key])
        .every(Boolean)

    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage1)
        || (page === 1 && !canNextPage2)
        || (page === 2 && !canNextPage3)
        || (page === 3 && !canNextPage4)
        || (page === 4 && !canNextPage5)
        || (page === 5 && !canNextPage6)
        || (page === 6 && !canNextPage7)
        || (page === 7 && !canNextPage8)
        || (page === 8 && !canNextPage9)
        || (page === 9 && !canNextPage10)
        || (page === 10 && !canNextPage11)

    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    const resetHide = gotResponse !== true && "remove-button"

    return (
        <FormContext.Provider value={{ title, stepCount, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide, progress, stackResponse, setStackResponse, resetHide, gotResponse, setGotResponse, handleTitleChange, searchTitle, setSearchTitle, jsonError, setJsonError}}>
            { children }
        </FormContext.Provider>
    )
}

export default FormContext