
import './Results.css'

export default function Results({ lenders, data }) {

    function appendUTMSource(url, source) {
        console.log('Processing URL:', url); // Debugging line

        if (!url || !source || (!url.startsWith('http://') && !url.startsWith('https://'))) {
            console.log('Invalid Website URL.');
            return { url: '#', isValid: false };
        }

        try {
            const urlObj = new URL(url);
            urlObj.search += urlObj.search ? `&utm_source=${encodeURIComponent(source)}` : `?utm_source=${encodeURIComponent(source)}`;
            return { url: urlObj.href, isValid: true };
        } catch (error) {
             // If an error occurs while creating the URL object, return '#'
             console.log('Error processing URL:', error);
             return { url: '#', isValid: false }
        }

      }

      function handleClick(url, isValid, event) {
        if (!isValid) {
            event.preventDefault(); 
            // Optionally, add some notification to the user here
        } else {
            window.open(url, '_blank');
        }
    }

    function formatLTV(ltv) {
        // Remove the '%' sign and convert to a number
        const numericLTV = parseFloat(ltv.replace('%', ''));
    
        // Check if the conversion is successful and the value is not NaN
        if (!isNaN(numericLTV)) {
            if (Number.isInteger(numericLTV)) {
                // If it's an integer, return it without decimal points
                return `${numericLTV}%`;
            } else {
                // If it's not an integer, return it with two decimal points
                return `${numericLTV.toFixed(2)}%`;
            }
        } else {
            // Handle the case where ltv is not a valid number
            return '0%'; // or any other fallback value you prefer
        }
    }

    function formatLenderLTV(ltvString) {
        // Use a regular expression to extract the percentage
        const match = ltvString.match(/(\d+(\.\d+)?%)/);
    
        // Check if a match is found
        if (match) {
            return match[0]; // Return the matched percentage part
        } else {
            // Handle the case where no valid percentage is found
            return 'N/A'; // or any other fallback value you prefer
        }
    }
    

  return (
    <>
        <div className='results-holder'>
        {lenders.map(lender => {

            const utmSource = 'account_executive_ai'
            const { url, isValid } = appendUTMSource(lender.website, utmSource);

            const formattedLTV = formatLTV(data.ltv);
            const formattedLenderLTV = formatLenderLTV(lender.ltv)

            return (
                <div key={lender.id} className='result-item'>
                <div className='result-title'>
                    <div className='title-holder'>
                        <h3 className='result-heading'>{lender.lender}</h3>
                        <div className={formattedLTV <= formattedLenderLTV ? 'match' : 'potential'}>
                            <div className={formattedLTV <= formattedLenderLTV ? 'match-text' : 'potential-text'}>{formattedLTV <= formattedLenderLTV ? 'Match' : 'Potential'}</div>
                        </div>
                    </div>
                    <button onClick={(e) => handleClick(url, isValid, e)} target='_blank' rel='noreferrer' type="button" className='result-website w-inline-block' disabled={!isValid}>
                        <div className='text-block-68'>Website</div>
                    </button>
                </div>
                <div className='result-info-holder'>
                    <div className='result-info'>
                        <div className='result-info-card'>
                            <div className='result-info-data'>{lender.maxLoanAmount}</div>
                            <div className='result-info-label'>Max Loan</div>
                        </div>

                        <div className='result-info-card'>
                            <div className='result-info-data'>{lender.ltv}</div>
                            <div className='result-info-label'>LTV</div>
                        </div>

                        <div className='result-info-card'>
                            <div className='result-info-data'>{lender.occupancy}</div>
                            <div className='result-info-label'>Occupancy</div>
                        </div>

                        <div className='result-info-card'>
                            <div className='result-info-data'>{lender.minimumFico}</div>
                            <div className='result-info-label'>FICO</div>
                        </div>
                    </div>
                    <div className='result-info-income-holder'>
                        <div className='result-info-label'>Income Documents:</div>
                        <div className='result-info-data full'>{lender.incomeType}</div>
                    </div>
                </div>
            </div>
            )
        })}
        </div>
    </>
  )
}